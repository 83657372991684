import React, { Component } from 'react'

export default class Page404 extends Component {
  render() {
    return (
      <div>
        Not Found
      </div>
    )
  }
}
