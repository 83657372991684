import React, { Component } from "react";
import homedata from "../../data/homedata.json";

class Services extends Component {
  render() {
    return (
      <section id="about" className="p-100px-tb sm-p-50px-b">
        <div className="container">
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <div className="section-title text-center m-50px-b">
                <h1>{homedata.about.sectiontitle}</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-12">
              <h2 className="text-center">WHO WE ARE</h2>
              <p style={{ fontSize: "20px" }}>
                The Alpha Minds, we are a team of seasoned software engineers,
                driven by innovation and expertise in local and global software
                project development and implementation & maintaince.
              </p>
            </div>
            <div className="col-12">
              <h2 className="text-center">WHAT WE DO</h2>
              <p style={{ fontSize: "20px" }}>
                At The Alpha Minds, we are your premier IT solutions partner,
                specializing in retail software and hardware. We offer
                end-to-end services designed to propel your business forward.
                From innovative technology implementations to tailored solutions
                for new ventures and expansions, our expertise spans diverse
                industries. The Alpha Minds is your gateway to maximizing the
                impact of your IT budget, ensuring seamless integration across
                all aspects of your business. We believe in fostering long-term
                relationships, and our commitment is to look after your business
                as if it were our own.
              </p>
            </div>
            {/* <div className="col-12">
              <h2 className="text-center">HOW WE DO IT</h2>
              <p style={{ fontSize: "20px" }}>
                The Alpha Minds, we are a team of seasoned software engineers,
                driven by innovation and expertise in local and global software
                project development and implementation.
              </p>
            </div> */}
            {/* { homedata.services.map( ( service, i ) => {
                  return <div key={i} className="col-lg-4 col-md-6">
                     <div className="service-box text-center p-60px lg-p-40px md-p-30px sm-p-20px m-10px-t m-10px-b">
                        <i className={service.icon}></i>
                        <h4>{service.title}</h4>
                        <p>{service.content}</p>
                     </div>
                  </div>
               } ) } */}
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
